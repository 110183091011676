import { toast } from 'react-toastify'
import URL from 'src/utils/URL'

const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'

export const getProductsRequest = () => ({
  type: GET_PRODUCTS_REQUEST,
})

export const getProductsSuccess = (products) => ({
  type: GET_PRODUCTS_SUCCESS,
  products,
})

export const getProductsFailure = (error) => ({
  type: GET_PRODUCTS_FAILURE,
  error,
})

export const fetchProducts = ({ categoryId, subcategoryId }) => {
  return (dispatch) => {
    dispatch(getProductsRequest())

    fetch(
      URL.BASE_URL +
        URL.END_POINT.products +
        `categoryId=${categoryId}&subcategoryId=${subcategoryId}&page=1&limit=50`, //&page=1&limit=10
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then(async (response) => {
        if (!response.ok) {
          const message = await response.json().then((res) => res.message)
          throw new Error(message)
        }
        return response.json()
      })
      .then(async (products) => {
        await dispatch(getProductsSuccess(products))
      })
      .catch((error) => {
        dispatch(getProductsFailure(error.message))
        toast(error.message, { type: 'error' })
        window.location.href = '/'
      })
  }
}

const initialProductsState = {
  products: null,
  loading: false,
  error: null,
}

const productsReducer = (state = initialProductsState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products.data,
        loading: false,
        error: null,
      }
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default productsReducer
