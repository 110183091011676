const URL = {
  BASE_URL: 'https://api.freshymeat.com/api/v1/',
  // BASE_URL: 'http://localhost:8085/api/v1/',
  PLAYSTORE: 'https://play.google.com/store/apps/details?id=com.freshymeat',
  FIREBASE: `https://freshymeat-app-default-rtdb.firebaseio.com/`,

  END_POINT: {
    login: 'loginwithotp',
    verify: 'verify',
    banner: 'banner',
    menus: 'menus',
    subcategories: 'subcategories?categoryId=',
    cart: 'cart',
    carts: 'cart?id=',
    checkout: 'checkout',
    products: 'products?',
    myprofile: 'profile',
    masterdata: 'master-data/',
    coupons: 'coupons/',
    orders: 'orders?id=',
    orderhistory: 'orderhistory?id=',
    privacy_policy: 'privacy_policy.json',
    terms_and_conditions: 'terms_and_conditions.json',
  },
}

export default URL
