import { GoogleApi } from 'src/utils/GoogleApi'

const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST'
const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS'
const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE'

export const getLocationRequest = () => ({
  type: GET_LOCATION_REQUEST,
})

export const getLocationSuccess = (locationData) => ({
  type: GET_LOCATION_SUCCESS,
  locationData,
})

export const getLocationFailure = (error) => ({
  type: GET_LOCATION_FAILURE,
  error,
})

export const fetchLocation = (myLat, myLon) => {
  return async (dispatch) => {
    dispatch(getLocationRequest())

    try {
      const responseJson = await GoogleApi.GetLocation(myLat, myLon)

      if (!responseJson || responseJson?.status !== 'OK') {
        throw new Error('Failed to fetch location')
      }

      const country = responseJson?.results[0]?.address_components?.filter(
        (x) => x.types.filter((t) => t === 'country').length > 0,
      )[0].long_name

      const state = responseJson?.results[0]?.address_components?.filter(
        (x) => x.types.filter((t) => t === 'administrative_area_level_1').length > 0,
      )[0].long_name

      const city = responseJson?.results[0]?.address_components?.filter(
        (x) => x.types.filter((t) => t === 'locality').length > 0,
      )[0].long_name

      const area = responseJson?.results[0]?.address_components?.filter(
        (x) => x.types.filter((t) => t === 'sublocality_level_1').length > 0,
      )[0].long_name

      const pincode = responseJson?.results[0]?.address_components?.filter(
        (x) => x.types.filter((t) => t === 'postal_code').length > 0,
      )[0].long_name

      // Dispatch success action
      dispatch(
        getLocationSuccess({
          area,
          city,
          state,
          country,
          pincode,
          coordinates: `${myLat},${myLon}`,
          address: responseJson?.results[1].formatted_address,
        }),
      )
    } catch (error) {
      dispatch(getLocationFailure(error.message))
    }
  }
}

const initialLocationState = {
  locationData: null,
  loading: false,
  error: null,
}

const locationReducer = (state = initialLocationState, action) => {
  switch (action.type) {
    case GET_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        locationData: action.locationData,
        loading: false,
        error: null,
      }
    case GET_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default locationReducer
